






















import { BTab, BTabs, BCard, BAlert, BLink } from 'bootstrap-vue';
import AreaAddTabInfo from './AreaAddTabInfo.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component( {
  name: 'AreaAdd',
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    AreaAddTabInfo,
  }
} )
export default class AreaAdd extends Vue {}
