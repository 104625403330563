































































































import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { AvButton } from "@/components";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { Ref, ref } from "@vue/composition-api";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Component, Vue } from "vue-property-decorator";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  IResponseGetRegionActive,
  IRegionApi,
} from "@core/services/interfaces/covarege-area/region/IRegionService";

@Component({
  name: "AreaAddTabInfo",
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BFormInvalidFeedback,
    vSelect,
    AvButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
})
export default class AreaAddTabInfo extends Vue {
  // Data
  required = required;
  loading: Ref<boolean> = ref(false);

  blankData: { name: string; code: number | null; region: number | null } = {
    name: "",
    code: null,
    region: null,
  };
  areaData = ref(JSON.parse(JSON.stringify(this.blankData)));
  regionOptions: { label: string; value: number | undefined }[] = [];

  resetData = () => {
    this.areaData.value = JSON.parse(JSON.stringify(this.blankData));
  };

  formValidation = formValidation(this.resetData);

  // Computeds
  get isLoading(): boolean {
    return this.loading.value;
  }

  // LifeCicly
  created() {
    this.$store
      .dispatch("admin-region/fetchGetRegionActives")
      .then((response: IResponseGetRegionActive) => {
        this.regionOptions = response.data.Data.map((region: IRegionApi) => ({
          label: region.Nome,
          value: region.Id,
        }));
      });
  }

  // Methods
  backList() {
    this.$router.push({ name: "admin-panel-area-list" });
  }

  onSubmit = (): void => {
    this.loading.value = true;
    this.$store
      .dispatch("admin-area/fetchAddArea", {
        nome: this.areaData.value.name,
        regiaoOperacionalId: this.areaData.value.region,
        codigo: this.areaData.value.code,
      })
      .then((response: any) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Área Operacional inserida com sucesso!",
            icon: "CheckIcon",
            variant: "success",
          },
        });

        this.$router.push({
          name: "admin-panel-area-edit",
          params: { id: "" + response.data.Data },
        });
      })
      .catch((response: any) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao inserir área operacional!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        this.loading.value = false;
      });
  };

  rediceValue = (option: { label: string; value: number }): number => {
    return option.value;
  };
}
